import { getFormFieldConversionsFromDb } from "../../../../app/firestore/firestoreService";
import {
  convertAddressCity,
  convertAddressCityStateZipcode,
  convertAddressFull,
  convertAddressState,
  convertAddressStreet,
  convertAddressStreetAndUnit,
  convertAddressZipcode,
  convertFullName,
  convertPartyLegalName,
  convertTransactionLegalNames,
} from "../../../../app/common/util/util";
import _ from "lodash";
import { format } from "date-fns";

export async function docPrepopulateFields(doc, parties, transaction) {
  let formFieldValues = {};
  if (!doc.fieldsPopulated && doc.hasFieldConversions) {
    const fieldConversions = await getFormFieldConversionsFromDb(
      doc.formId,
      transaction
    );
    let convertedParties = convertPartyInfo(parties);

    fieldConversions.forEach((item) => {
      if (convertedParties[item.convertTo?.split("_")?.[0]]) {
        let value = "";
        value =
          convertedParties[item.convertTo.split("_")[0]][
            item.convertTo.split("_")[1]
          ];
        if (value) {
          formFieldValues[item.pdfFieldName] = value;
        }
      }
    });
  }
  return formFieldValues;
}


export function convertPartyInfo(parties) {
  let partiesCopy = _.cloneDeep(parties);
  let convertedParty = {};

  partiesCopy.forEach((party) => {
    if (party.lastName) {
      party.fullname = convertFullName(party);
    }
    if (!_.isEmpty(party.address)) {
      party.addressFull = convertAddressFull(party.address);
      party.addressStreet = convertAddressStreet(party.address);
      party.addressStreetAndUnit = convertAddressStreetAndUnit(party.address);
      party.addressCity = convertAddressCity(party.address);
      party.addressState = convertAddressState(party.address);
      party.addressZipcode = convertAddressZipcode(party.address);
      party.addressCityStateZipcode = convertAddressCityStateZipcode(
        party.address
      );
    }
    if (party.role === "Buyer") {
      party.legalName = convertPartyLegalName(party);
      convertedParty.buyer = party;
    }
    if (party.role === "Buyer 2") {
      party.legalName = convertPartyLegalName(party);
      convertedParty.buyer2 = party;
    }
    if (party.role === "Seller") {
      party.legalName = convertPartyLegalName(party);
      convertedParty.seller = party;
    }
    if (party.role === "Seller 2") {
      party.legalName = convertPartyLegalName(party);
      convertedParty.seller2 = party;
    }
    if (party.role === "Buyer Agent") {
      convertedParty.brokerBuyer = party;
    }
    if (party.role === "Listing Agent") {
      convertedParty.brokerSeller = party;
    }
    if (party.role === "Transaction") {
      const transactionLegalNames = convertTransactionLegalNames(parties);
      party.buyerLegalName = transactionLegalNames?.buyer;
      party.sellerLegalName = transactionLegalNames?.seller;
      party.addressCounty = party.propertyDetails?.county;
      party.addressLegal = party.propertyDetails?.legalDescription;
      party.inclusions = party.propertyDetails?.inclusions;
      party.exclusions = party.propertyDetails?.exclusions;
      party.yearBuilt = party.propertyDetails?.yearBuilt;
      party.brokerageLogoRef = party.agentProfile?.brokerageLogoRef || "";
      party.contractDateText =
        (party.contractDateTime &&
          format(party.contractDateTime, "M/d/yyyy")) ||
        "";
      party.currentDate = new Date();
      party.checked = true;
      party.checkedText = "X";
      convertedParty.transaction = party;
    }
  });
  if (convertedParty?.transaction?.agentRepresents === "Buyer") {
    convertedParty.transaction.clientFullNames = convertedParty.transaction.buyerLegalName;
    convertedParty.transaction.client1FullName = convertedParty.buyer.legalName;
    convertedParty.transaction.client2FullName = (convertedParty.buyer2 ? convertedParty.buyer2.legalName : "");
    convertedParty.meAgent = convertedParty.brokerBuyer;
  } else if (convertedParty?.transaction?.agentRepresents === "Seller") {
    convertedParty.transaction.clientFullNames = convertedParty.transaction.sellerLegalName;
    convertedParty.transaction.client1FullName = convertedParty.seller.legalName;
    convertedParty.transaction.client2FullName = (convertedParty.seller2 ? convertedParty.seller2.legalName : "");
    convertedParty.meAgent = convertedParty.brokerSeller;
  } 
  return convertedParty;
}
