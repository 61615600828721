export function realBrokerageWireFraudDisclosure() {
return [   //2024 Release 2025-03-06 17:31:30
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 590.698,
   left: 113.215,
   width: 156.000,
   height: 13.680
}
,
{
   page: 0,
   name: "Name_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 588.844,
   left: 361.56,
   width: 156.00,
   height: 14.334
}
,
{
   page: 0,
   name: "Name_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 698.8582,
   left: 361.92,
   width: 156.00,
   height: 13.6799
}
,
{
   page: 0,
   name: "Name_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 700.1382,
   left: 109.08,
   width: 156.00,
   height: 13.6799
}
] }
