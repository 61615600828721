import React from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import {
  updatePartyInDb,
  updateTaskAddSharingInDb,
  updateTransAddSharingInDb,
} from "../../../app/firestore/firestoreService";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import { closeModal } from "../../../app/common/modals/modalSlice";

export default function TaskShareAgentWarning({ task, party, transaction }) {
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const dispatch = useDispatch();

  function handleShareWithoutLinking() {
    try {
      updateTaskAddSharingInDb(task.id, party, transaction);
      updateTransAddSharingInDb(transaction.id, party, transaction);
      updatePartyInDb(party, { declinedLinking: true });
    } catch (error) {
      throw error;
    }
    toast.success("Task shared without linking transaction.");
    dispatch(
      closeModal({
        modalType: "TaskShareAgentWarning",
      })
    );
  }

  return (
    <>
      <ModalWrapper size="small">
        <Segment>
          <Grid>
            <Grid.Column>
              <Header size="large" color="blue">
                Share Deadline
              </Header>
              <Divider />
              <p className="text-large text blue bold small bottom margin">
                {party.firstName} {party.lastName} is also using TransActioner.
              </p>
              <p className="text-medium small bottom margin">
                We can share this deadline with {party.firstName}{" "}
                {party.lastName} but since they are using Transactioner it would
                be better to link your transaction with theirs. You can do this
                by sharing a Document with them first and the you'll see info
                about how to link the transaction.
              </p>
              <p className="text-medium tiny bottom margin">
                If you aren't able to link the transaction or don't want to,
                then you can click the "Share Without Linking" button and we'll
                email the agent a link to where the deadline can be viewed.
              </p>
              <Button
                onClick={() => handleShareWithoutLinking()}
                to="#"
                floated={isMobile ? null : "right"}
                content="Share Without Linking"
                className={isMobile ? "fluid medium bottom margin" : null}
              />
              <Button
                onClick={() =>
                  dispatch(
                    closeModal({
                      modalType: "TaskShareAgentWarning",
                    })
                  )
                }
                to="#"
                type="button"
                floated={isMobile ? null : "right"}
                content="Cancel"
                className={isMobile ? "fluid medium" : null}
              />
            </Grid.Column>
          </Grid>
        </Segment>
      </ModalWrapper>
    </>
  );
}
