export function realBrokerageAffiliatedBusinessDisclosure() {
return [   //2024 Release 2025-03-06 16:49:46
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Client Names", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 137.68,
   left: 73.68,
   width: 426.00,
   height: 16.364
}
,
{
   page: 0,
   name: "PropertyAddr", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 201.28,
   left: 101.64,
   width: 402.00,
   height: 16.364
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 234.71,
   left: 81.164,
   width: 95.672,
   height: 14.80
}
,
{
   page: 2,
   name: "Client Names2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 139.735,
   left: 73.68,
   width: 426.00,
   height: 13.745
}
,
{
   page: 2,
   name: "Agent Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 200.062,
   left: 89.64,
   width: 312.00,
   height: 17.018
}
,
{
   page: 2,
   name: "Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 234.36,
   left: 101.64,
   width: 402.00,
   height: 14.40
}
,
{
   page: 2,
   name: "Specifically Real Broker owns", 
   isText: false,
   type: "checkbox",
   top: 340.07999,
   left: 54,
   width: 9.720001,
   height: 12.96002
}
,
{
   page: 2,
   name: "Date2", 
   fontSize: 9,
   type: "date",
   top: 267.056,
   left: 83.1276,
   width: 81.9274,
   height: 14.145
}
] }
