export function sellersOffertoBuyersBroker() {
return [   //2024 Release 2025-02-17 09:15:16
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "Name", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 65.24,
   left: 154.68,
   width: 401.16,
   height: 14.902
}
,
{
   page: 0,
   name: "Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 109.16,
   left: 160.2,
   width: 390.48,
   height: 14.902
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 153.08,
   left: 90.48,
   width: 219.96,
   height: 14.902
}
,
{
   page: 0,
   name: "Compensation", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 264.92,
   left: 99.84,
   width: 60.60,
   height: 14.902
}
,
{
   page: 0,
   name: "of the total purchase price or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 264.92,
   left: 310.08,
   width: 209.04,
   height: 14.902
}
] }
