import React from "react";
import { Button, Grid, Input } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  convertPartiesAbleToShare,
  partyIsAgent,
  searchFilter,
} from "../../../../app/common/util/util";
import TaskTransSharingList from "./TaskTransSharingList";
import {
  updateTaskAddSharingInDb,
  updateTransAddSharingInDb,
} from "../../../../app/firestore/firestoreService";
import _ from "lodash";

export default function TaskTransSharingDashboard() {
  const { tasksTransWithDateAll } = useSelector((state) => state.task);
  let { id } = useParams();
  const [searchTerms, setSearchTerms] = useState("");
  const deadlines = searchFilter(tasksTransWithDateAll, searchTerms);

  const { transaction, allParties } = useSelector((state) => state.transaction);

  const partiesAvailToShare = convertPartiesAbleToShare(
    allParties,
    transaction.agentRepresents
  );

  function shareDeadlinesWithParty(party) {
    deadlines.forEach((deadline) => {
      const partyRoleCamel = _.camelCase(party.role);
      if (deadline.sharingWithRole?.[partyRoleCamel]) {
        // already shared
      } else {
        if (!(partyIsAgent(party.role) && party.isUser)) {
          try {
            updateTaskAddSharingInDb(deadline.id, party, transaction);
            updateTransAddSharingInDb(transaction.id, party, transaction);
          } catch (error) {
            throw error;
          }
        }
      }
    });
  }

  function handleSharingAllClick() {
    // REFACTOR: does one at a time, should convert db to handle the list
    if (!deadlines || !partiesAvailToShare) return;

    const partiesToShare = partiesAvailToShare.filter(
      (party) => party?.email?.trim().length > 0
    );
    partiesToShare.forEach((party) => {
      shareDeadlinesWithParty(party);
    });

    return;
  }

  return (
    <div className="secondary-page-wrapper">
      <Grid stackable>
        <Grid.Column computer={5}>
          <Input
            type="text"
            fluid
            placeholder="Search by name or status"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          ></Input>
        </Grid.Column>
        <Grid.Column computer={5}>
          <Button.Group fluid size="small">
            <Button as={Link} to={`/transactions/${id}/tasks`}>
              List
            </Button>
            <Button as={Link} to={`/transactions/${id}/tasksCalendar`}>
              Calendar
            </Button>
            <Button active as={Link} to="">
              Sharing
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column computer={6}></Grid.Column>
        <Grid.Column computer={16}>
          <h3>Shareable Deadlines</h3>
          {deadlines?.length > 0 ? (
            <>
              <div className="float right">
                <Button onClick={() => handleSharingAllClick()}>
                  Share All Deadlines With All Parties
                </Button>
              </div>
              <br />
              <TaskTransSharingList deadlines={deadlines} />
            </>
          ) : (
            <p>There are no shareable deadlines for this transaction</p>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}
