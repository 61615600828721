import React, { useState } from "react";
import {
  Button,
  Segment,
  Label,
  Grid,
  Header,
  Divider,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import {
  functionAddAuthClaimAssistant,
  functionAddAuthClaimRole,
  functionCreateNewUser,
  functionCreateViewInDataFiniti,
  functionDelAuthClaimAssistant,
  functionFetchViewsFromDataFiniti,
  functionImpersonateUser,
  functionUpdateUserEmail,
  functionViewAllUsersWithCustomClaims,
  functionViewAuthClaimRole,
} from "../../app/firestore/functionsService";
import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MySelectInput from "../../app/common/form/MySelectInput";
import { signInCustomAuth } from "../../app/firestore/firebaseService";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalSlice";
import { getFormFieldConversionsFromDb } from "../../app/firestore/firestoreService";
import MyEmailInput from "../../app/common/form/MyEmailInput";
import FormAddress from "../../app/common/form/FormAddress";
import { useMediaQuery } from "react-responsive";
import MyRadioButtons from "../../app/common/form/MyRadioButtons";

const FormAction = {
  authView: "view",
  authSet: "set",
  authAssist: "assist",
  authDeleteAssist: "deleteAssist",
  authNone: "none",
};

const ROLE_TYPE_KEYS = {
  ADMIN: "admin",
  TC: "tc",
  ASSISTANT: "assistant",
  MANAGING_BROKER: "managingbroker",
};
const ROLE_TYPE_VALUES = {
  ADMIN: "d",
  TC: "t",
  ASSISTANT: "a",
  MANAGING_BROKER: "m",
};

let roleOptions = [
  { key: "", value: "", text: "" },
  {
    key: ROLE_TYPE_KEYS.ADMIN,
    value: ROLE_TYPE_VALUES.ADMIN,
    text: "Admin",
  },
  {
    key: ROLE_TYPE_KEYS.TC,
    value: ROLE_TYPE_VALUES.TC,
    text: "TC",
  },
  {
    key: ROLE_TYPE_KEYS.ASSISTANT,
    value: ROLE_TYPE_VALUES.ASSISTANT,
    text: "Assistant",
  },
  {
    key: ROLE_TYPE_KEYS.MANAGING_BROKER,
    value: ROLE_TYPE_VALUES.MANAGING_BROKER,
    text: "Managing Broker",
  },
];

let userRoleOptions = [
  {
    key: "agent",
    value: "agent",
    text: "agent",
  },
  {
    key: "assistant",
    value: "assistant",
    text: "assistant",
  },
  {
    key: "coagent",
    value: "coagent",
    text: "coagent",
  },
  {
    key: "manager",
    value: "manager",
    text: "manager",
  },
  {
    key: "tc",
    value: "tc",
    text: "tc",
  },
];

let userStateOptions = [
  {
    key: "Colorado",
    value: "Colorado",
    text: "Colorado",
  },
  {
    key: "Louisiana",
    value: "Louisiana",
    text: "Louisiana",
  },
  {
    key: "Oklahoma",
    value: "Oklahoma",
    text: "Oklahoma",
  },
  {
    key: "Texas",
    value: "Texas",
    text: "Texas",
  },
];
let userCitiesOptions = [
  {
    key: "",
    value: "",
    text: "",
  },
  {
    key: "Avon OR Glenwood Springs OR Beaver Creek OR Edwards OR West Vail OR Minturn OR Eagles Nest OR Dowds Junction OR Vail OR Eagle Vail OR Red Cliff OR Copper Mountain",
    value:
      "Avon OR Glenwood Springs OR Beaver Creek OR Edwards OR West Vail OR Minturn OR Eagles Nest OR Dowds Junction OR Vail OR Eagle Vail OR Red Cliff OR Copper Mountain",
    text: "Vail / Copper / Glenwood Springs / etc",
  },
  {
    key: "Colorado Springs OR Pueblo OR Monument OR Fountain OR Woodland Park OR Manitou Springs OR Florissant OR Falcon OR Larksburg OR Palmer Lake OR Elbert OR Cripple Creek OR Green Mountain Falls",
    value:
      "Colorado Springs OR Pueblo OR Monument OR Fountain OR Woodland Park OR Manitou Springs OR Florissant OR Falcon OR Larksburg OR Palmer Lake OR Elbert OR Cripple Creek OR Green Mountain Falls",
    text: "Colorado Springs / Pueblo",
  },
  {
    key: "Montrose OR Pagosa Springs OR Durango OR South Fork OR Ridgway OR Cortez OR Cedaredge OR Olathe OR Austin OR Delta",
    value:
      "Montrose OR Pagosa Springs OR Durango OR South Fork OR Ridgway OR Cortez OR Cedaredge OR Olathe OR Austin OR Delta",
    text: "Montrose",
  },
  {
    key: "Canon City OR Penrose OR Florence OR Wetmore OR Pueblo West OR Pueblo OR Westcliffe OR Texas Creek OR Hillside OR Guffey OR Coaldale OR Howard",
    value:
      "Canon City OR Penrose OR Florence OR Wetmore OR Pueblo West OR Pueblo OR Westcliffe OR Texas Creek OR Hillside OR Guffey OR Coaldale OR Howard",
    text: "Canon City / Pueblo West / Westciffe / Penrose",
  },
  {
    key: "Grand Junction OR Fruita OR Palisade OR Mack OR Loma OR Glade Park OR Mesa OR Molina OR Clifton OR Whitewater OR Parachute",
    value:
      "Grand Junction OR Fruita OR Palisade OR Mack OR Loma OR Glade Park OR Mesa OR Molina OR Clifton OR Whitewater OR Parachute",
    text: "Grand Junction / Fruita",
  },
  {
    key: "Gunnison OR Parlin OR Almont OR Crested Butte OR Pitkin OR Ohio City",
    value:
      "Gunnison OR Parlin OR Almont OR Crested Butte OR Pitkin OR Ohio City",
    text: "Gunnison / Crested Butte",
  },
  {
    key: "Durango OR Mancos OR Hesperus OR Bayfield OR Ignacio OR Arboles",
    value: "Durango OR Mancos OR Hesperus OR Bayfield OR Ignacio OR Arboles",
    text: "Durango",
  },
  {
    key: "Paonia OR Crawford OR Hotchkiss OR Delta OR Cedaredge OR Somerset",
    value: "Paonia OR Crawford OR Hotchkiss OR Delta OR Cedaredge OR Somerset",
    text: "Paonia",
  },
  {
    key: "Pagosa Springs OR Chromo OR Arboles OR Bayfield OR Durango OR Homelake OR Ignacio OR Monte Vista OR South Fork",
    value:
      "Pagosa Springs OR Chromo OR Arboles OR Bayfield OR Durango OR Homelake OR Ignacio OR Monte Vista OR South Fork",
    text: "Pagosa Springs",
  },
];

const userBrokerageLogoOptions = [
  { key: "true", text: "true", value: "true" },
  { key: "false", text: "false", value: "false" },
];

export default function AdminPage() {
  const { forms } = useSelector((state) => state.doc);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [authLog, setAuthLog] = useState([]);
  const [usersWithCustomClaims, setUsersWithCustomClaims] = useState({});
  const [formFieldConversionArray, setFormFieldConversionArray] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const dispatch = useDispatch();

  function logResult(result) {
    console.log(" AUTH::result = ", result?.result);
    setAuthLog([...authLog, result?.result]);
    toast.success(result?.result);
  }
  function logError(error) {
    console.log("AUTH:: ERROR: ", error);
    toast.error(error.message);
  }

  async function handleViewUserRole(data) {
    try {
      const result = await functionViewAuthClaimRole(data);
      logResult(result);
    } catch (error) {
      logError(error);
    }
  }

  async function handleAuthRole(data) {
    try {
      const result = await functionAddAuthClaimRole(data);
      logResult(result);
    } catch (error) {
      logError(error);
    }
  }

  async function handleAuthAssistant(data) {
    try {
      const result = await functionAddAuthClaimAssistant(data);
      logResult(result);
    } catch (error) {
      logError(error);
    }
  }

  async function handleAuthAssistDelete(data) {
    try {
      const result = await functionDelAuthClaimAssistant(data);
      logResult(result);
    } catch (error) {
      logError(error);
    }
  }

  async function handleGetDataFinitiViews() {
    try {
      const result = await functionFetchViewsFromDataFiniti();
      console.log("DATAFINITI VIEWS: ", result);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  async function handleCreateDataFinitiView() {
    try {
      const result = await functionCreateViewInDataFiniti();
      console.log("DATAFINITI VIEWS: ", result);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  async function handleViewAllCustomClaims() {
    try {
      let usersByRole = { tcs: [], assistants: [], managers: [], admins: [] };
      const result = await functionViewAllUsersWithCustomClaims();
      result.forEach((user) => {
        if (user.authCustomClaims?.r === "t") {
          usersByRole.tcs.push(user);
        } else if (user.authCustomClaims?.r === "a") {
          usersByRole.assistants.push(user);
        } else if (user.authCustomClaims?.r === "m") {
          usersByRole.managers.push(user);
        } else if (user.authCustomClaims?.r === "d") {
          usersByRole.admins.push(user);
        }
      });
      console.log("USER BY ROLE: ", usersByRole);
      setUsersWithCustomClaims(usersByRole);
    } catch (error) {
      logError(error);
    }
  }

  async function handleUpdateUserEmail(data) {
    try {
      console.log("UPDATE USER EMAIL DATA: ", data);
      const result = await functionUpdateUserEmail(data);
      console.log("TOKEN: ", result);
      //   signInCustomAuth(result)
      //     .then((userCredential) => {
      //       console.log("SIGNED IN AS: ", userCredential.user);
      //       window.location.reload(false);
      //     })
      //     .catch((error) => {
      //       logError(error);
      //     });
    } catch (error) {
      logError(error);
    }
  }

  async function handleImpersonate(data) {
    try {
      console.log("IMPERSONATE DATA: ", data);
      const result = await functionImpersonateUser(data);
      console.log("TOKEN: ", result);
      signInCustomAuth(result)
        .then((userCredential) => {
          console.log("SIGNED IN AS: ", userCredential.user);
          window.location.reload(false);
        })
        .catch((error) => {
          logError(error);
        });
    } catch (error) {
      logError(error);
    }
  }

  async function handleViewFormFieldConversions() {
    let fieldConversionArray = [];
    forms &&
      forms.forEach(async (form) => {
        // if (form.title !== "Agreement to Revive") {
        //   return;
        // }
        console.log("GETTING FORMID: ", form.id);
        const fieldConversions = await getFormFieldConversionsFromDb(
          form.id,
          currentUserProfile
        );
        console.log("FIELDCONVERSIONS", fieldConversions);
        fieldConversionArray = [...fieldConversionArray, { title: form.title }];
        fieldConversions.forEach((fieldConversion) => {
          fieldConversionArray = [...fieldConversionArray, fieldConversion];
        });
        console.log("FIELDARRAY BEFORE: ", fieldConversionArray);
        setFormFieldConversionArray(fieldConversionArray);
      });
  }

  return (
    <div className="main-page-wrapper">
      <Segment clearing>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
          })}
          onSubmit={(values, { setSubmitting, setFieldValue }) => {
            handleImpersonate(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            errors,
          }) => (
            <Form className="ui form medium margin bottom">
              <Header size="huge" color="blue">
                Impersonate User
              </Header>
              <Divider />
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={8}>
                    <Grid.Column width={4}>
                      <MyTextInput
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Email address"
                      />
                      {errors.auth && (
                        <Label
                          basic
                          color="red"
                          style={{ marginBottom: 10 }}
                          content={errors.auth}
                        />
                      )}
                    </Grid.Column>

                    <Grid.Column>
                      <br />
                      <Button
                        name="button"
                        value={FormAction.authSet}
                        primary
                        type="button"
                        onChange={handleChange}
                        loading={isSubmitting}
                        disabled={!isValid || !dirty || isSubmitting}
                        onClick={() => {
                          handleSubmit();
                        }}
                        size="medium"
                        style={{ marginBottom: 10 }}
                        content="Impersonate User"
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Segment>
      <Segment clearing>
        <Formik
          initialValues={{
            email: "",
            role: "agent",
            firstName: "",
            lastName: "",
            state: "Colorado",
            brokerageForms: "",
            hasBrokerageLogo: "true",
            phone: "",
            middleName: "",
            brokerageLogoRef: "",
            brokerageName: "",
            brokerageId: "",
            brokerageLicenseNumber: "",
            brokerLicenseNumber: "",
            brokerNrdsId: "",
            address: {
              street: "",
              unit: "",
              city: "",
              state: "",
              zipcode: "",
            },
          }}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
            role: Yup.string().required(),
            firstName: Yup.string().required(),
            lastName: Yup.string().required(),
            state: Yup.string().required(),
            brokerageForms: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              await functionCreateNewUser(values);
              resetForm();
              setSubmitting(false);
              toast.success("Profile successfully updated");
            } catch (error) {
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form className="ui form medium margin bottom">
              <Header size="huge" color="blue">
                Create New User
              </Header>
              <Header color="blue">Name & Contact Info</Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput name="firstName" label="First name" />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput name="middleName" label="Middle name" />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput name="lastName" label="Last name" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="zero top padding">
                  <Grid.Column mobile={16} computer={5}>
                    <MyEmailInput name="email" label="Email" />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput name="phone" label="Phone" />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <MySelectInput
                      name="role"
                      label="Role"
                      options={userRoleOptions}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="zero top padding">
                  <Grid.Column mobile={16} computer={5}>
                    <MySelectInput
                      name="state"
                      label="State"
                      options={userStateOptions}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="zero top padding">
                  <Grid.Column mobile={16} computer={5}>
                    <MySelectInput
                      name="cities"
                      label="Cities"
                      options={userCitiesOptions}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Header color="blue">Agent/Broker Details</Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput
                      name="brokerageForms"
                      label="Brokerage Forms"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput
                      name="brokerageLogoRef"
                      label="Brokerage Logo Ref"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <p className="bold">Has Brokerage Logo</p>
                    <MyRadioButtons
                      name="hasBrokerageLogo"
                      options={userBrokerageLogoOptions}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="zero top padding">
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput name="brokerageName" label="Brokerage Name" />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput name="brokerageId" label="Brokerage ID" />
                  </Grid.Column>

                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput
                      name="brokerageLicenseNumber"
                      label="Brokerage License Number"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="zero top padding">
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput
                      name="brokerLicenseNumber"
                      label="Agent License Number"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={5}>
                    <MyTextInput name="brokerNrdsId" label="Agent NRDS ID" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Header color="blue">Brokerage Address</Header>
              <FormAddress />
              <br />
              <Button
                loading={isSubmitting}
                disabled={!dirty || isSubmitting}
                type="submit"
                floated={isMobile ? "left" : "right"}
                primary
                content="Add New User"
                className={isMobile ? "fluid large" : "large"}
              />
            </Form>
          )}
        </Formik>
      </Segment>

      <Segment clearing>
        <Formik
          initialValues={{
            email: "",
            role: "",
            agentAssistingEmail: "",
            action: "none",
          }}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
          })}
          onSubmit={(values, { setSubmitting, setFieldValue }) => {
            switch (values.action) {
              case FormAction.authView:
                handleViewUserRole(values);
                break;
              case FormAction.authSet:
                handleAuthRole(values);
                break;
              case FormAction.authAssist:
                handleAuthAssistant(values);
                break;
              case FormAction.authAssistDelete:
                handleAuthAssistDelete(values);
                break;
              case FormAction.authNone:
                break;
              default:
                console.log("should never be called");
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            errors,
          }) => (
            <Form className="ui form medium margin bottom">
              <Header size="huge" color="blue">
                Create User Roles
              </Header>
              <p className="bold text-medium">
                All TCs, Assistants, and Managers MUST have their role set here
                in order to work properly on the site.
              </p>
              <p>Page accessible by TransActioner admins only.</p>
              <Divider />
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={8}>
                    <Grid.Column width={4}>
                      <MyTextInput
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Email address"
                        // onChange={handleChange}
                        value={values.email}
                      />
                      <MySelectInput
                        name="role"
                        label="Role"
                        options={roleOptions}
                      ></MySelectInput>

                      {errors.auth && (
                        <Label
                          basic
                          color="red"
                          style={{ marginBottom: 10 }}
                          content={errors.auth}
                        />
                      )}
                    </Grid.Column>

                    <Grid.Column>
                      <br />
                      <Button
                        name="button"
                        value={FormAction.authView}
                        primary
                        type="submit"
                        onChange={handleChange}
                        loading={isSubmitting}
                        disabled={
                          !isValid || !dirty || isSubmitting || !values.email
                        }
                        size="medium"
                        style={{ marginBottom: 10 }}
                        content="View User Auth Role"
                        onClick={() => {
                          values.action = FormAction.authView;
                          handleSubmit();
                        }}
                      />
                      <br />
                      <Button
                        name="button"
                        value={FormAction.authSet}
                        primary
                        type="button"
                        onChange={handleChange}
                        loading={isSubmitting}
                        disabled={
                          !isValid || !dirty || isSubmitting || !values.role
                        }
                        onClick={() => {
                          values.action = FormAction.authSet;
                          handleSubmit();
                        }}
                        size="medium"
                        style={{ marginBottom: 10 }}
                        content="Set User Auth Role"
                      />
                    </Grid.Column>
                  </Grid.Column>

                  <Grid.Column mobile={16} computer={8}>
                    <h3>Set Assisting Agent</h3>
                    <p>
                      To add an agent for this user to assist as either
                      'assistant' or 'tc', you need to also fill out the role
                      here.
                      <br />
                      Note: TCs are only added here as somewhat 'permanent' TCs
                      for all transactions for a particular user.{" "}
                      <b>
                        This enables a TC to create a new transaction for an
                        agent.
                      </b>
                      <br />
                      If the TC only assists on particular transactions, then
                      the agent adds them as a party to the transaction.
                    </p>

                    <MyTextInput
                      name="agentAssistingEmail"
                      type="text"
                      label="Email of Agent To Assist"
                      value={values.agentAssistingEmail}
                    />
                    <Button
                      name="button"
                      value={FormAction.authAssist}
                      primary
                      type="submit"
                      onChange={handleChange}
                      loading={isSubmitting}
                      disabled={
                        !isValid ||
                        !dirty ||
                        isSubmitting ||
                        !values.agentAssistingEmail ||
                        !values.role
                      }
                      onClick={() => {
                        values.action = FormAction.authAssist;
                        handleSubmit();
                      }}
                      size="medium"
                      style={{ marginBottom: 10 }}
                      content="Add agent to who this user assists"
                    />
                    <Button
                      name="button"
                      value={FormAction.authAssistDelete}
                      primary
                      type="submit"
                      onChange={handleChange}
                      loading={isSubmitting}
                      disabled={
                        !isValid ||
                        !dirty ||
                        isSubmitting ||
                        !values.agentAssistingEmail
                      }
                      onClick={() => {
                        values.action = FormAction.authAssistDelete;
                        handleSubmit();
                      }}
                      size="medium"
                      style={{ marginBottom: 10 }}
                      content="Remove an agent from who this user assists"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  Console Log:
                  <div>
                    <ul>
                      {authLog.map(function (name, index) {
                        return <li key={index}>{name}</li>;
                      })}
                    </ul>
                  </div>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Segment>
      <Segment clearing>
        <div className="ui form medium margin bottom">
          <Header size="huge" color="blue">
            View All Users With Custom Claims
          </Header>
          <Divider />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={16}>
                <Button
                  primary
                  onClick={() => {
                    handleViewAllCustomClaims();
                  }}
                  size="medium"
                  style={{ marginBottom: 10 }}
                  content="View"
                />
              </Grid.Column>
              <Grid.Column mobile={16} computer={4}>
                <p className="bold text blue">TCs</p>
                {usersWithCustomClaims?.tcs?.map(function (user) {
                  return (
                    <p>
                      {user.firstName} {user.lastName} {user.email}
                    </p>
                  );
                })}
              </Grid.Column>
              <Grid.Column mobile={16} computer={4}>
                <p className="bold text blue">Assistants</p>
                {usersWithCustomClaims?.assistants?.map(function (user) {
                  return (
                    <p>
                      {user.firstName} {user.lastName} {user.email}{" "}
                      {user.authCustomClaims.a[0]} {user.authCustomClaims.a[1]}
                    </p>
                  );
                })}
              </Grid.Column>
              <Grid.Column mobile={16} computer={4}>
                <p className="bold text blue">Managers</p>
                {usersWithCustomClaims?.managers?.map(function (user) {
                  return (
                    <p>
                      {user.firstName} {user.lastName} {user.email}
                    </p>
                  );
                })}
              </Grid.Column>
              <Grid.Column mobile={16} computer={4}>
                <p className="bold text blue">Admins</p>
                {usersWithCustomClaims?.admins?.map(function (user) {
                  return (
                    <p>
                      {user.firstName} {user.lastName} {user.email}
                    </p>
                  );
                })}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
      <Segment clearing>
        <Formik
          initialValues={{
            email: "",
            emailNew: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
            emailNew: Yup.string().required().email(),
          })}
          onSubmit={(values, { setSubmitting, setFieldValue }) => {
            handleUpdateUserEmail(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            errors,
          }) => (
            <Form className="ui form medium margin bottom">
              <Header size="huge" color="blue">
                Update User Email
              </Header>
              <Divider />
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={8}>
                    <Grid.Column width={4}>
                      <MyTextInput
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Old Email address"
                      />
                      <MyTextInput
                        name="emailNew"
                        type="email"
                        label="Email New"
                        placeholder="New Email address"
                      />
                      {errors.auth && (
                        <Label
                          basic
                          color="red"
                          style={{ marginBottom: 10 }}
                          content={errors.auth}
                        />
                      )}
                    </Grid.Column>

                    <Grid.Column>
                      <br />
                      <Button
                        name="button"
                        value={FormAction.authSet}
                        primary
                        type="button"
                        onChange={handleChange}
                        loading={isSubmitting}
                        disabled={!isValid || !dirty || isSubmitting}
                        onClick={() => {
                          handleSubmit();
                        }}
                        size="medium"
                        style={{ marginBottom: 10 }}
                        content="Update User Email"
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Segment>

      <Segment clearing>
        <div className="ui form medium margin bottom">
          <Header size="huge" color="blue">
            DataFiniti MLS Views
          </Header>
          <Divider />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={16}>
                <Button
                  primary
                  onClick={() => {
                    handleGetDataFinitiViews();
                  }}
                  size="medium"
                  style={{ marginRight: 20 }}
                  content="See Views"
                />
                <Button
                  primary
                  onClick={() => {
                    handleCreateDataFinitiView();
                  }}
                  size="medium"
                  style={{ marginBottom: 10 }}
                  content="Create View"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
      <Segment clearing>
        <Formik
          initialValues={{
            logoStoragePath: "",
          }}
          validationSchema={Yup.object({
            logoStoragePath: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting, setFieldValue }) => {
            dispatch(
              openModal({
                modalType: "AdminLogoPhotoUpload",
                modalProps: { logoStoragePath: values.logoStoragePath },
              })
            );
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            errors,
          }) => (
            <Form className="ui form medium margin bottom">
              <Header size="huge" color="blue">
                Brokerage Photos
              </Header>
              <Divider />
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} computer={8}>
                    <Grid.Column width={4}>
                      <MyTextInput
                        name="logoStoragePath"
                        type="text"
                        label="Logo Storage Path"
                        placeholder="Examples: CB/DistinctiveProperties"
                      />
                      {errors.auth && (
                        <Label
                          basic
                          color="red"
                          style={{ marginBottom: 10 }}
                          content={errors.auth}
                        />
                      )}
                    </Grid.Column>

                    <Grid.Column>
                      <br />
                      <Button
                        name="button"
                        value={FormAction.authSet}
                        primary
                        type="submit"
                        onChange={handleChange}
                        loading={isSubmitting}
                        disabled={!isValid || !dirty || isSubmitting}
                        size="medium"
                        style={{ marginBottom: 10 }}
                        content="Upload Photo"
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Segment>
      <Segment clearing>
        <div className="ui form medium margin bottom">
          <Header size="huge" color="blue">
            View FormField Conversions From Database
          </Header>
          <Divider />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={16}>
                <Button
                  primary
                  onClick={() => {
                    handleViewFormFieldConversions();
                  }}
                  size="medium"
                  style={{ marginRight: 20 }}
                  content="View"
                />
              </Grid.Column>
              <Grid.Column mobile={16} computer={16}>
                {formFieldConversionArray &&
                  formFieldConversionArray.forEach((formField) => (
                    <>
                      {formField.title && <p>{formField.title}</p>}
                      {formField.convertTo && <p>{formField.convertTo}</p>}
                      {formField.pdfFieldName && (
                        <p>{formField.pdfFieldName}</p>
                      )}
                    </>
                  ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>
    </div>
  );
}
