import React from "react";
import { isSameDay } from "date-fns";
import { Table, Popup, Label, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { serverTimestamp } from "firebase/firestore";
import _ from "lodash";
import {
  updateTaskAddSharingInDb,
  updateTaskInDb,
  updateTaskRemoveSharingInDb,
  updateTransAddSharingInDb,
} from "../../../../app/firestore/firestoreService";
import { openModal } from "../../../../app/common/modals/modalSlice";
import { functionShareTaskWithAgent } from "../../../../app/firestore/functionsService";
import {
  convertFullName,
  convertPartiesAbleToShare,
  convertRoleToSharingDisplay,
  convertSharingWithToColor,
  dateFormatDeadline,
  partyIsAgent,
} from "../../../../app/common/util/util";
import TaskActionButtons from "../../taskComponents/TaskActionButtons";

export default function TaskTransSharingListItem({ deadline }) {
  const dispatch = useDispatch();
  const { transaction, allParties } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);

  const partiesExceptOtherAgentOnTA = convertPartiesAbleToShare(
    allParties,
    transaction.agentRepresents
  ).filter((party) => !(partyIsAgent(party.role) && party.isUser));

  function handleSharingClick(party) {
    const partyRoleCamel = _.camelCase(party.role);
    if (deadline.sharingWithRole?.[partyRoleCamel]) {
      try {
        updateTaskRemoveSharingInDb(deadline.id, party, transaction);
      } catch (error) {
        throw error;
      }
    } else {
      if (partyIsAgent(party.role) && party.isUser && !party.isLinked) {
        dispatch(
          openModal({
            modalType: "TaskShareAgentWarning",
            modalProps: {
              task: deadline,
              party: party,
              transaction: transaction,
            },
          })
        );
      } else {
        try {
          updateTaskAddSharingInDb(deadline.id, party, transaction);
          updateTransAddSharingInDb(transaction.id, party, transaction);
        } catch (error) {
          throw error;
        }
        if (partyIsAgent(party.role) && party.isUser && party.isLinked) {
          updateTaskInDb(deadline.id, {
            sentToAgent: true,
            sentToAgentId: party.id,
            sentToAgentAt: serverTimestamp(),
          });
          const sharedByName = convertFullName(currentUserProfile);
          functionShareTaskWithAgent({
            deadline: deadline,
            party: party,
            sharedBy: sharedByName,
          });
        }
      }
    }
  }

  return (
    <Table.Row
      key={deadline.id}
      className={
        deadline.status === "Complete"
          ? "background-green"
          : isSameDay(deadline.end, new Date())
          ? "background-blue"
          : // : isPast(deadline.end)
            // ? "background-yellow"
            ""
      }
    >
      <Table.Cell>{dateFormatDeadline(deadline.end)}</Table.Cell>
      <Table.Cell>
        {deadline.title}{" "}
        {deadline.description && (
          <Popup
            trigger={
              <Icon
                name="info"
                color="blue"
                circular
                size="small"
                style={{ marginLeft: "3px", marginBottom: "3px" }}
              />
            }
          >
            {deadline.description}
          </Popup>
        )}
      </Table.Cell>
      <Table.Cell>
        {/* {convertPartiesAbleToShare(allParties, transaction.agentRepresents).map( */}
        {partiesExceptOtherAgentOnTA.map((party) => (
          <React.Fragment key={party.role}>
            {party.email && (
              <Popup
                trigger={
                  <Label
                    key={party.role}
                    style={{ cursor: "pointer", marginBottom: "2px" }}
                    color={convertSharingWithToColor(deadline, party)}
                    onClick={() => handleSharingClick(party)}
                  >
                    {convertRoleToSharingDisplay(party.role)}
                  </Label>
                }
              >
                <p>
                  {party.role}:
                  <br />
                  {convertFullName(party)}
                </p>
              </Popup>
            )}
          </React.Fragment>
        ))}
      </Table.Cell>
      <Table.Cell>
        <TaskActionButtons task={deadline} />
      </Table.Cell>
    </Table.Row>
  );
}
