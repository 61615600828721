import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Confirm,
  Divider,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  deletePartyInDb,
  updateTransactionInDb,
  updateTransClientInDb,
} from "../../../app/firestore/firestoreService";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {
  createClientRoleOptions,
  createRestrictedEmails,
  partyIsBuyerOrSeller,
} from "../../../app/common/util/util";
import FormParty from "../../../app/common/form/FormParty";
import FormAddress from "../../../app/common/form/FormAddress";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useMediaQuery } from "react-responsive";

export default function TransactionClientEditForm({ client, transaction }) {
  const dispatch = useDispatch();
  const { allParties } = useSelector((state) => state.transaction);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { docsTransActive, docsTransArchived, docsTransShared } = useSelector(
    (state) => state.doc
  );
  const { tasksTransWithDateAll } = useSelector((state) => state.task);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  const clientRoleOptions = createClientRoleOptions(client, transaction);

  const originalClientEmail = client.email;

  const restrictedEmails = createRestrictedEmails(
    currentUserProfile,
    transaction,
    allParties,
    client
  );

  let emailIsNotEditable = true;
  if (!client.email || !transaction.sharingWith?.includes(client.email)) {
    emailIsNotEditable = false;
  }

  let initialValues = client;

  const [isTrustField, setIsTrustField] = useState(
    initialValues.isTrust ? true : false
  );

  const validationSchema = Yup.object({
    lastName: Yup.string().required("You must provide a last name"),
    role: Yup.string().required("You must provide a role"),
    email: Yup.string()
      .email("Must be a valid email")
      .notOneOf(restrictedEmails, "Can't use same email for different parties"),
  });

  async function handleDelete() {
    try {
      updateTransactionInDb(transaction.id, {
        clientSecondary: {},
        clientSecondaryExists: false,
      });
      deletePartyInDb(client);
      toast.success("client successfully deleted");
      dispatch(
        closeModal({
          modalType: "TransactionClientEditForm",
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <ModalWrapper>
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const docsInTransaction =
                  values.email !== originalClientEmail
                    ? docsTransActive?.docs
                        ?.concat(docsTransArchived?.docs)
                        .concat(docsTransShared?.docs)
                    : [];

                const docsNeedSharingUpdated = docsInTransaction
                  ? docsInTransaction?.filter((doc) =>
                      doc.sharingWith?.includes(originalClientEmail)
                    )
                  : [];
                await updateTransClientInDb(
                  transaction,
                  values,
                  originalClientEmail,
                  docsNeedSharingUpdated,
                  tasksTransWithDateAll
                );

                setSubmitting(false);
                toast.success("Client successfully updated");
                dispatch(
                  closeModal({
                    modalType: "TransactionClientEditForm",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid }) => (
              <Form className="ui form">
                <Header size="large" color="blue">
                  Edit Client
                </Header>
                <Divider />
                <Grid>
                  <Grid.Column mobile={16} computer={5}>
                    <MySelectInput
                      name="role"
                      placeholder="Role"
                      options={clientRoleOptions}
                    />
                  </Grid.Column>
                </Grid>
                <Divider />
                <FormParty edit={emailIsNotEditable} />

                {partyIsBuyerOrSeller(client.role) && (
                  <>
                    <br />
                    <MyCheckbox
                      name="isTrust"
                      label="This client is acting on behalf of a company, trust, or other entity"
                      onClick={() => setIsTrustField(!isTrustField)}
                    />
                    {isTrustField && (
                      <MyTextInput
                        name="entityName"
                        label="Company, trust, or entity name"
                      />
                    )}
                  </>
                )}
                <Divider />
                <FormAddress />
                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "TransactionClientEditForm",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                {!(client.role === "Buyer" || client.role === "Seller") &&
                  !isMobile && (
                    <Icon
                      name="trash"
                      link
                      onClick={() => setConfirmOpen(true)}
                    />
                  )}
                {!(client.role === "Buyer" || client.role === "Seller") &&
                  isMobile && (
                    <Button
                      type="button"
                      basic
                      color="red"
                      content="Delete"
                      fluid
                      onClick={() => setConfirmOpen(true)}
                    />
                  )}
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleDelete()}
      ></Confirm>
    </ModalWrapper>
  );
}
