export function exclusiveRighttoBuyCB2025() {
return [   //2024 Release 2025-02-17 09:40:03
 {
   page: 0,
 name: "Logo",
 type: "logo",
 top: 28.673,
 left: 53,
 width: 240,
 height: 36,
 }, 
{
   page: 0,
   name: "BUYER AGENCY", 
   isText: false,
   type: "checkbox",
   top: 243.96,
   left: 158.04,
   width: 12.24,
   height: 12.24
}
,
{
   page: 0,
   name: "TRANSACTIONBROKERAGE", 
   isText: false,
   type: "checkbox",
   top: 243.96,
   left: 301.8,
   width: 12.24,
   height: 12.24
}
,
{
   page: 0,
   name: "Date", 
   fontSize: 9,
   type: "date",
   top: 272.04,
   left: 426.12,
   width: 125.04,
   height: 16.80
}
,
{
   page: 0,
   name: "Buyer", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 518.64,
   left: 126.0,
   width: 435.0,
   height: 13.80
}
,
{
   page: 0,
   name: "propertyDescrip", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 585.764,
   left: 64.9086,
   width: 510.4384,
   height: 96.000
}
,
{
   page: 0,
   name: "Broker", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 554.16,
   left: 130.44,
   width: 430.08,
   height: 13.80
}
,
{
   page: 1,
   name: "means any agreement between a landlord and the Buyer to create a tenancy or leasehold interest in the Property", 
   isText: false,
   type: "checkbox",
   top: 36.36,
   left: 112.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "Listing Period The Listing Period of this Buyer Listing Contract begins on", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 66.48,
   left: 401.04,
   width: 129.96,
   height: 12.36
}
,
{
   page: 1,
   name: "2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 92.88,
   left: 94.8,
   width: 144.96,
   height: 12.36
}
,
{
   page: 1,
   name: "eg three days after MEC the first day is excluded and the last day is included If any deadline falls on a Saturday", 
   isText: false,
   type: "checkbox",
   top: 266.28,
   left: 407.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "that is not a Saturday Sunday or Holiday Should neither box be checked the deadline will not be extended", 
   isText: false,
   type: "checkbox",
   top: 266.28,
   left: 364.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 1,
   name: "seller as a customer", 
   isText: false,
   type: "checkbox",
   top: 548.76,
   left: 125.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "of the purchase price or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 593.28,
   left: 237.96,
   width: 50.04,
   height: 12.36
}
,
{
   page: 2,
   name: "plus 259 except as", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 593.28,
   left: 405.72,
   width: 64.92,
   height: 12.36
}
,
{
   page: 2,
   name: "See  21 Additional Provisions or", 
   isText: false,
   type: "checkbox",
   top: 628.68,
   left: 223.56,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "Other", 
   isText: false,
   type: "checkbox",
   top: 641.88,
   left: 90.12,
   width: 10.08,
   height: 10.08
}
,
{
   page: 2,
   name: "undefined", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 639.36,
   left: 134.16,
   width: 429.96,
   height: 12.36
}
,
{
   page: 3,
   name: "Retainer Fee Buyer will pay Brokerage Firm a nonrefundable retainer fee of", 
   isText: false,
   type: "checkbox",
   top: 55.08,
   left: 104.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "due and", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 51.6,
   left: 441,
   width: 75,
   height: 13.32
}
,
{
   page: 3,
   name: "Will", 
   isText: false,
   type: "checkbox",
   top: 68.28,
   left: 347.4,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will Not be credited against other fees", 
   isText: false,
   type: "checkbox",
   top: 68.28,
   left: 394.92,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Other Compensation", 
   isText: false,
   type: "checkbox",
   top: 100.68,
   left: 104.28,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "undefined_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 97.2,
   left: 214.8,
   width: 349.92,
   height: 13.32
}
,
{
   page: 3,
   name: "per square", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 149.76,
   left: 164.04,
   width: 65.04,
   height: 13.32
}
,
{
   page: 3,
   name: "or", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 149.76,
   left: 275.4,
   width: 90.0,
   height: 13.32
}
,
{
   page: 3,
   name: "undefined_3", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 149.76,
   left: 381.24,
   width: 185.04,
   height: 13.32
}
,
{
   page: 3,
   name: "See  21 Additional Provisions or_2", 
   isText: false,
   type: "checkbox",
   top: 186.12,
   left: 201,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Other_2", 
   isText: false,
   type: "checkbox",
   top: 186.12,
   left: 373.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "undefined_4", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 182.76,
   left: 422.16,
   width: 99.96,
   height: 13.32
}
,
{
   page: 3,
   name: "undefined_5", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 202.08,
   left: 144.6,
   width: 425.04,
   height: 13.32
}
,
{
   page: 3,
   name: "If the Lease", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 262.32,
   left: 132.96,
   width: 380.04,
   height: 12.36
}
,
{
   page: 3,
   name: "Will_2", 
   isText: false,
   type: "checkbox",
   top: 291.24,
   left: 463.08,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will Not be", 
   isText: false,
   type: "checkbox",
   top: 291.24,
   left: 505.32,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "undefined_6", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 328.32,
   left: 78.6,
   width: 480.0,
   height: 13.32
}
,
{
   page: 3,
   name: "Buyer Will Pay Buyer is obligated to pay Brokerage Firms Success Fee Compensation Brokerage Firm is", 
   isText: false,
   type: "checkbox",
   top: 435.48,
   left: 95.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "calendar days after the Listing Period expires Holdover Period 1 if the", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 520.56,
   left: 196.2,
   width: 50.04,
   height: 12.36
}
,
{
   page: 3,
   name: "Will_3", 
   isText: false,
   type: "checkbox",
   top: 549.72,
   left: 335.64,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Will Not owe the Brokerage Firms", 
   isText: false,
   type: "checkbox",
   top: 549.72,
   left: 380.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Is", 
   isText: false,
   type: "checkbox",
   top: 696.84,
   left: 324.84,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Is Not currently a party to any agreement with any", 
   isText: false,
   type: "checkbox",
   top: 696.84,
   left: 356.52,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Has", 
   isText: false,
   type: "checkbox",
   top: 723.48,
   left: 58.68,
   width: 10.08,
   height: 10.08
}
,
{
   page: 3,
   name: "Has Not received a list of any Submitted Property pursuant to a previous listing agreement to purchase or lease", 
   isText: false,
   type: "checkbox",
   top: 723.48,
   left: 99.24,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Buyers identity to third parties without prior written consent of Buyer", 
   isText: false,
   type: "checkbox",
   top: 459.24,
   left: 296.76,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "Does Not have Buyers permission to disclose", 
   isText: false,
   type: "checkbox",
   top: 459.24,
   left: 341.16,
   width: 10.08,
   height: 10.08
}
,
{
   page: 4,
   name: "brokerageServices", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 279.492,
   left: 68.073,
   width: 508.693,
   height: 82.873
}
,
{
   page: 4,
   name: "Text38", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 398.274,
   left: 79.8549,
   width: 472.6921,
   height: 14.146
}
,
{
   page: 4,
   name: "Text39", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 426.766,
   left: 79.8549,
   width: 496.9111,
   height: 29.854
}
,
{
   page: 6,
   name: "undefined_8", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 732,
   left: 285.24,
   width: 279.96,
   height: 12.6
}
,
{
   page: 6,
   name: "addProv", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 89.019,
   left: 60.873,
   width: 505.420,
   height: 483.456
}
,
{
   page: 6,
   name: "attachm", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 585.166,
   left: 59.5639,
   width: 508.0381,
   height: 38.364
}
,
{
   page: 7,
   name: "Buyer 1", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 281.4,
   left: 90.0,
   width: 205.08,
   height: 20.378
}
,
{
   page: 7,
   name: "Date brokerSigns", 
   fontSize: 9,
   type: "date",
   top: 304.92,
   left: 467.52,
   width: 89.28,
   height: 10.56
}
,
{
   page: 7,
   name: "Street Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 325.8,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Brokerage Firm Street Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 325.8,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 7,
   name: "City State Zip", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 346.44,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Brokerage Firm City State Zip", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 346.44,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 7,
   name: "Phone No", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 367.08,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Broker Phone No", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 367.08,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 7,
   name: "Fax No", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 387.72,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Broker Fax No", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 387.72,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 7,
   name: "Email Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 408.6,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Broker Email Address", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 408.6,
   left: 310.08,
   width: 240.00,
   height: 10.56
}
,
{
   page: 7,
   name: "Buyer 1_2", 
   type: "textarea",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 438.36,
   left: 90.0,
   width: 200.04,
   height: 21.687
}
,
{
   page: 7,
   name: "Street Address_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 482.76,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "City State Zip_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 503.4,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Phone No_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 524.04,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Fax No_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 544.68,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Email Address_2", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 565.56,
   left: 58.08,
   width: 234.96,
   height: 10.56
}
,
{
   page: 7,
   name: "Buyer 3 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 595.32,
   left: 105.36,
   width: 187.20,
   height: 10.56
}
,
{
   page: 7,
   name: "signature brokerSigns", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 304.177,
   left: 311.139,
   width: 149.564,
   height: 10.560
}
,
{
   page: 7,
   name: "Buyer 4 1", 
   type: "text",
   fontName: "Times New Roman",
   fontSize: 9,
   top: 648.36,
   left: 102.48,
   width: 190.08,
   height: 10.56
}
,
{
   page: 7,
   name: "signature cobrokerSigns", 
   type: "signature",
   fontName: "Times New Roman",
   fontSize: 10,
   top: 461.705,
   left: 309.83,
   width: 151.527,
   height: 10.560
}
,
{
   page: 7,
   name: "Date cobrokerSigns", 
   fontSize: 9,
   type: "date",
   top: 461.88,
   left: 467.52,
   width: 89.28,
   height: 10.56
}
] }
